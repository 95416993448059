import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AddHabit from './AddHabit';
import Task from './components/Task';
import { Modal, Empty } from 'antd';

const HabitList = ({ userId }) => {
    const [habits, setHabits] = useState([]);

    const fetchHabits = async () => {
        try {
            const response = await axios.get('https://api.habitmaker.bruteforce.kz/habits.php', {
                params: { user_id: userId }
            });

            console.log('Fetched habits:', response.data);

            // Convert 'isdone' from 1/0 to true/false
            const convertedHabits = response.data.map(habit => ({
                ...habit,
                isdone: habit.isdone == 1
            }));

            setHabits(convertedHabits);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchHabits();
    }, [userId]);

    const handleToggle = async (id, isdone) => {
        try {
            console.log('Toggling habit state...');
            console.log('Habit ID:', id);
            console.log('Current isdone:', isdone);

            const response = await axios.put('https://api.habitmaker.bruteforce.kz/habits.php', { id, isdone: !isdone });
            console.log('Toggle habit response:', response.data);

            const canVibrate = window.navigator.vibrate;
            
            if( isdone ){
                if (canVibrate) {
                    window.navigator.vibrate(40);
                }
            } else {
                if (canVibrate) {
                    window.navigator.vibrate(80);
                }
            }

            setHabits(habits.map(habit => habit.id === id ? { ...habit, isdone: !isdone } : habit));
            fetchHabits();
        } catch (error) {
            console.error('Error during toggle habit:', error.response ? error.response.data : error.message);
        }
    };

    const handleDelete = (id) => {
        // Find the habit by id
        const habitToDelete = habits.find(habit => habit.id === id);
        
        if (!habitToDelete) {
            console.error('Habit not found');
            return;
        }

        Modal.confirm({
            title: `Are you sure you want to delete the habit "${habitToDelete.name}"?`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                try {
                    await axios.delete('https://api.habitmaker.bruteforce.kz/habits.php', { data: { id } });
                    fetchHabits(); // Refresh the list of habits
                } catch (error) {
                    console.error(error);
                }
            },
        });
    };

    return (
        <div className="habits">
            {habits.length > 0 ? (
                <div className="habits-list">
                    {habits.map(habit => (
                        <Task
                            key={habit.id}
                            habit={habit}
                            onToggle={handleToggle}
                            onDelete={handleDelete}
                        />
                    ))}
                </div>
            ) : (
                <div className="empty-state">
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={
                            <span>
                                Let's start a new habit, click the button below
                            </span>
                        }
                    />
                </div>
            )}
            <AddHabit userId={userId} onAdd={fetchHabits} />
        </div>
    );
};

export default HabitList;




