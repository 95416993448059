import './Task.css';
import React from 'react';
import { Dropdown, Menu, Button, Progress } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { motion } from 'framer-motion';

const Task = ({ habit, onToggle, onDelete }) => {
    const menu = (
        <Menu>
            <Menu.Item key="0" onClick={() => onDelete(habit.id)}>
                Delete
            </Menu.Item>
        </Menu>
    );

    function calculatePercentage(x, y) {
        if (y === 0) {
          return 0;
        }
        const percentage = (x / y) * 100;
        return parseInt(percentage);
    }

    const checkVariants = {
        checked: { scale: [1, 1.1, 0.3, 1.7, 0.7, 1] },
        unchecked: { scale: 1 }
    };

    return (
        <div className={`task ${habit.isdone ? 'task-done' : 'task-not-done'}`} key={habit.id}>

            <div className="task-status">
                <motion.button 
                    className="task-check" 
                    onClick={() => onToggle(habit.id, habit.isdone)}
                    animate={habit.isdone ? "checked" : "unchecked"}
                    variants={checkVariants}
                    transition={{ duration: 0.5 }}
                >
                    <motion.svg 
                        width="18" 
                        height="13" 
                        viewBox="0 0 18 13" 
                        fill={habit.isdone ? "#fff" : "none"} 
                        xmlns="http://www.w3.org/2000/svg"
                        initial={false}
                        animate={{ pathLength: habit.isdone ? 1 : 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <motion.path 
                            d="M6.9997 10.1709L16.1921 0.978523L17.6063 2.39273L6.9997 12.9993L0.635742 6.6354L2.04996 5.2212L6.9997 10.1709Z"
                            stroke={habit.isdone ? "#fff" : "#000"}
                            strokeWidth="2"
                        />
                    </motion.svg>
                </motion.button>
            </div>
            
            <div className="task-details">
                <div className="task-name">{habit.name}</div>
                <div className="task-descr">{habit.description}</div>
                <Progress 
                    percent={calculatePercentage(habit.streak, habit.goal)} 
                    showInfo={false}
                    strokeColor="#59CA07"
                    size="small"
                />
            </div>

            <div className="task-actions">
                <Dropdown overlay={menu} trigger={['click']}>
                    <Button style={{ border: 'none' }}>
                        <MoreOutlined />
                    </Button>
                </Dropdown>
            </div>

        </div>
    );
};

export default Task;