import React, { useState } from 'react';
import axios from 'axios';
import { Button, Form, Input, message } from 'antd';



const Register = ({ onRegister }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
            console.log('Sending registration request...');
            const response = await axios.post('https://api.habitmaker.bruteforce.kz/users.php', {
                username, password
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log('Registration response:', response.data);
            setShowSuccessMessage(true);
            setTimeout(() => {
                setShowSuccessMessage(false);
                onRegister();
            }, 2000);
        } catch (error) {
            console.error('Error during registration:', error.response ? error.response.data : error.message);
            setError('Registration failed. Please try again.');
        }
    };

    if (showSuccessMessage) {
        return (
            <div style={{
                position: 'fixed',
                zIndex: 999,
                inset: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#f0f2f5',
                fontSize: '18px',
                color: '#333'
            }}>
                Registration successful
            </div>
        );
    }

    return (
        <div>
            <form className="login-form" onSubmit={handleSubmit}>
                <h2 className="login-form-title">Register</h2>

                {showSuccessMessage && (
                    <div style={{ color: 'green', marginBottom: '16px' }}>
                        Registration successful!
                    </div>
                )}

                <Form.Item label="Username" layout="vertical">
                    <Input
                        size="large"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </Form.Item>

                <Form.Item label="Password" layout="vertical">
                    <Input.Password
                        size="large"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Form.Item>

                <Button
                    htmlType='submit'
                    type='primary'
                    block
                    size='large'
                >Register</Button>

                {error && <p style={{ color: 'red' }}>{error}</p>}
            </form>
        </div>
    );
};

export default Register;

