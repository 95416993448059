import './Button.css';
import React from 'react';

const Button = ({ 
  text, 
  theme = 'primary', 
  size = 'medium', 
  type = 'button', 
  fullWidth = false,
  onClick 
}) => {
  const baseClass = 'btn';
  const themeClass = `btn-${theme}`;
  const sizeClass = `btn-${size}`;
  const widthClass = fullWidth ? 'btn-block' : '';
  
  const className = `${baseClass} ${themeClass} ${sizeClass} ${widthClass}`.trim();

  return (
    <button className={className} onClick={onClick} type={type}>
      {text}
    </button>
  );
};

export default Button;