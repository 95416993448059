import React, { useState } from 'react';
import axios from 'axios';
import CustomButton from './components/Button';
import { Button, Form, Input } from 'antd';


const Login = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            console.log('Sending login request...');
            console.log('Username:', username);
            console.log('Password:', password);

            const response = await axios.get('https://api.habitmaker.bruteforce.kz/users.php', {
                params: { username, password }
            });
            console.log('Login response:', response.data);
            onLogin(response.data.user_id);
        } catch (error) {
            console.error('Error during login:', error.response ? error.response.data : error.message);
            setError('Login failed. Please try again.');
        }
    };

    return (
        <form className="login-form" onSubmit={handleSubmit}>

            <h2 className="login-form-title">Login</h2>

            <Form.Item label="Username" layout="vertical">
                <Input
                    size="large"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
            </Form.Item>

            <Form.Item label="Password" layout="vertical">
                <Input.Password
                    size="large"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </Form.Item>

            <Button
                htmlType='submit'
                type='primary'
                block
                size='large'
            >Log In</Button>

            {error && <p style={{ color: 'red' }}>{error}</p>}
        </form>
    );
};

export default Login;
